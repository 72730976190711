import React, { forwardRef, useCallback, useMemo, useState } from "react";

import { extractAtomsFromProps } from "@dessert-box/core";
import { useCombobox, useMultipleSelection } from "downshift";

import { getSprinkles } from "~styles/getSprinkles.css";

import { Box } from "~components/Box";
import { Input } from "~components/Input";
import { Label } from "~components/Label";

import { DropdownMenu } from "../common/_DropdownMenu";
import { downshiftStateReducer } from "../common/_downshiftStateReducer";
import { getDefaultHighlightedIndex } from "../common/_getDefaultHighlightedIndex";
import { getDisplayValue } from "../common/_getDisplayValue";
import { getFilteredDropdownItems } from "../common/_getFilteredDropdownItems";
import { getIsSelected } from "../common/_getIsSelected";

import type {
  UseComboboxStateChange,
  UseMultipleSelectionStateChange,
} from "downshift";
import type { Ref } from "react";
import type { IconFontAwesomeProps } from "~components/IconFontAwesome";
import type { InputProps } from "~components/Input";
import type { VariantInputAppearanceEnum } from "~components/Input/styles.css";
import type { VariantUiSizeEnum } from "~styles/common/variantUiScale.css";
import type { GetSprinklesArgs } from "~styles/getSprinkles.css";
import type { DropdownItemShape } from "~types/global.types";

export interface SelectMultipleProps extends GetSprinklesArgs {
  items: Array<DropdownItemShape>;
  name: string;
  placeholder: string;
  disabled?: boolean;
  errorMessage?: string;
  iconLeft?: IconFontAwesomeProps["icon"];
  iconRightClosed?: IconFontAwesomeProps["icon"];
  iconRightOpened?: IconFontAwesomeProps["icon"];
  initialHighlightedItem?: DropdownItemShape;
  initialSelectedItem?: never;
  inputProps?: Partial<InputProps>;
  initialSelectedItems?: Array<DropdownItemShape>;
  invalid?: boolean;
  isFilterable?: boolean;
  isMulti?: true;
  isOpen?: boolean;
  itemToString?: (item: DropdownItemShape | null) => string;
  onIsOpenChange?: (changes: UseComboboxStateChange<DropdownItemShape>) => void;
  onChange?: (
    changes: UseMultipleSelectionStateChange<DropdownItemShape>
  ) => void;
  size?: VariantUiSizeEnum;
  inputAppearance?: VariantInputAppearanceEnum;
  label?: string;
  id: string;
}

/** Accessible select component, supports multi & single modes. */
export const SelectMultiple = forwardRef(
  (
    {
      disabled,
      iconLeft,
      iconRightClosed,
      iconRightOpened,
      id,
      initialHighlightedItem,
      initialSelectedItems = [],
      inputProps,
      invalid,
      isFilterable,
      isOpen: controlledIsOpen,
      items,
      label,
      name,
      onIsOpenChange,
      onChange,
      placeholder,
      size,
      inputAppearance,
      ...rest
    }: SelectMultipleProps,
    ref: Ref<HTMLInputElement>
  ) => {
    /** Vanilla extract styling stuff */

    const { atomProps: inputAtomProps, otherProps: inputOtherProps } =
      extractAtomsFromProps(inputProps, getSprinkles);

    /** Externally controlled state for downshift and {@link Input} component */

    const [inputValue, setInputValue] = useState("");
    const [inputPlaceholder, setInputPlaceholder] = useState(placeholder);

    /**
     * ------------------------------------------------------------------------------
     * Callback to update input value and call `onChange` (if provided) when selected items change.
     * ------------------------------------------------------------------------------
     */

    const onSelectedItemsChange = useCallback(
      (changes: UseMultipleSelectionStateChange<DropdownItemShape>) => {
        if (onChange) {
          onChange(changes);
        }
        setInputPlaceholder(
          getDisplayValue({
            originalValue: placeholder,
            selectedItems: changes?.selectedItems,
          })
        );
      },
      [onChange, placeholder]
    );

    /**
     * ------------------------------------------------------------------------------
     * Downshift `useMultipleSelection` hook
     * @see https://www.downshift-js.com/use-multiple-selection
     * ------------------------------------------------------------------------------
     */

    const {
      getSelectedItemProps,
      getDropdownProps,
      removeSelectedItem,
      selectedItems,
      setSelectedItems,
    } = useMultipleSelection<DropdownItemShape>({
      onSelectedItemsChange,
      initialSelectedItems,
    });

    /**
     * ------------------------------------------------------------------------------
     * When `isFilterable` is true, we need to filter the items based on the input value.
     * ------------------------------------------------------------------------------
     */

    const filteredItems = useMemo(() => {
      if (!items || !isFilterable) return items;

      return getFilteredDropdownItems({ items, inputValue });
    }, [items, isFilterable, inputValue]);

    /**
     * ------------------------------------------------------------------------------
     * Downshift `useCombobox` hook
     * @see https://www.downshift-js.com/use-combobox
     * ------------------------------------------------------------------------------
     */

    const {
      isOpen,

      toggleMenu,
      getMenuProps,
      getInputProps,
      highlightedIndex,
      getItemProps,
    } = useCombobox<DropdownItemShape>({
      defaultHighlightedIndex: getDefaultHighlightedIndex({
        initialHighlightedItem,
        items,
      }),
      items: filteredItems,
      isOpen: controlledIsOpen,
      onIsOpenChange,
      onStateChange({
        inputValue: newInputValue,
        type,
        selectedItem: newSelectedItem,
      }) {
        switch (type) {
          case useCombobox.stateChangeTypes.InputKeyDownEnter:
          case useCombobox.stateChangeTypes.ItemClick:
          case useCombobox.stateChangeTypes.InputBlur:
            if (newSelectedItem) {
              setSelectedItems([...selectedItems, newSelectedItem]);
            }
            break;

          case useCombobox.stateChangeTypes.InputChange:
            if (typeof newInputValue !== "undefined") {
              setInputValue(newInputValue);
            }

            break;
          default:
            break;
        }
      },
      selectedItem: null,
      stateReducer: (state, actionAndChanges) => {
        return downshiftStateReducer(state, actionAndChanges, {
          isMulti: true,
        });
      },
    });

    /**
     * ------------------------------------------------------------------------------
     * Util function for checking if item is selected, passed down to DropdownItem via DropdownMenu
     * ------------------------------------------------------------------------------
     */

    const getIsItemSelected = useCallback(
      (item: DropdownItemShape) => {
        return getIsSelected({
          item,
          isMulti: true,
          selectedItems,
        });
      },
      [selectedItems]
    );

    /**
     * ------------------------------------------------------------------------------
     * Markup for Select component
     * ------------------------------------------------------------------------------
     */

    return (
      <Box position="relative" {...rest}>
        {label && <Label htmlFor={id} label={label} />}

        <Input
          size={size}
          appearance={inputAppearance}
          iconLeft={iconLeft}
          iconRight={isOpen ? iconRightOpened : iconRightClosed}
          readOnly={!isFilterable}
          invalid={invalid}
          {...inputAtomProps}
          {...(getInputProps &&
            getInputProps({
              ...getDropdownProps({ preventKeyAction: isOpen, ref }),
              ...inputOtherProps,
              disabled,
              id,
              name,
              onClick: toggleMenu,
              placeholder: inputPlaceholder,
              value: inputValue,
            }))}
        />

        <DropdownMenu
          getSelectedItemProps={getSelectedItemProps}
          getIsItemSelected={getIsItemSelected}
          getItemProps={getItemProps}
          getMenuProps={getMenuProps}
          highlightedIndex={highlightedIndex}
          isOpen={isOpen}
          removeSelectedItem={removeSelectedItem}
          items={filteredItems}
          isMulti
          size={size}
        />
      </Box>
    );
  }
);
