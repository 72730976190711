import React from "react";

import { Slot } from "@radix-ui/react-slot";
import { storyblokEditable } from "@storyblok/react";

import { getIsInEditor } from "~utils/storyblok/getIsInEditor";

import type { ReactNode } from "react";
import type { StoryblokBlok } from "~types/storyblok.types";

const ENV = process.env.GATSBY_ENV || "development";

interface EditableProps extends StoryblokBlok {
  children: ReactNode;
}

export function StoryblokEditable({
  children,
  _uid,
  component,
  _editable,
  ...rest
}: EditableProps) {
  if (
    ENV !== "production" &&
    _editable &&
    _uid &&
    component &&
    getIsInEditor()
  ) {
    return (
      <Slot {...rest} {...storyblokEditable({ _uid, component, _editable })}>
        {children}
      </Slot>
    );
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}
