import React from "react";

import * as styles from "./styles.css";

import type { ReactNode } from "react";

/** Applies italic font style to rich text  */
export function RichTextItalic(children: ReactNode) {
  return <span className={styles.italicRichText}>{children}</span>;
}
