import React, { forwardRef } from "react";

import { faCheck } from "@fortawesome/sharp-regular-svg-icons/faCheck";
import clsx from "clsx";

import { createInternalLink } from "~utils/link_utils/createInternalLink";

import { Box } from "~components/Box";
import { IconFontAwesome } from "~components/IconFontAwesome";
import { Link } from "~components/Link";

import * as styles from "./styles.css";

import type { Ref } from "react";
import type { VariantUiSizeEnum } from "~styles/common/variantUiScale.css";
import type { DropdownItemShape } from "~types/global.types";

export interface DropdownItemProps {
  isHighlighted: boolean;
  isMultipleSelectionEnabled?: boolean;
  size?: VariantUiSizeEnum;
  isMulti?: boolean;
  item: DropdownItemShape;
  isDropdownItemSelected?: boolean;
}

export const DropdownItem = forwardRef(
  (
    {
      item,
      size,
      isHighlighted,
      isMulti,
      isDropdownItemSelected,
      ...rest
    }: DropdownItemProps,
    ref
  ) => {
    const { label, link, decorativeNode, description } = item || {};

    const linkClassNames = clsx(
      styles.getDropdownItemStyles({
        size,
      }),
      {
        [styles.isHighlighted]: isHighlighted,
        [styles.isSelected({ isMulti })]: isDropdownItemSelected,
      }
    );
    const checkboxWrapper = clsx(
      styles.checkboxWrapper({ isHighlighted, isDropdownItemSelected })
    );

    const checkboxIcon = clsx(styles.checkboxIcon({ isDropdownItemSelected }));
    if (link) {
      return (
        <Link
          data-testid={`option-${label}`}
          id={label}
          link={createInternalLink(link)}
          className={linkClassNames}
          {...rest}
          ref={ref as Ref<HTMLAnchorElement>}
        >
          {decorativeNode}
          <Box flexShrink="0">
            <Box>{label}</Box>
            <Box color="text_lowContrast" fontSize="body_md">
              {description}
            </Box>
          </Box>
        </Link>
      );
    }

    return (
      <Box
        as="button"
        id={label}
        className={linkClassNames}
        {...rest}
        ref={ref as Ref<HTMLElement>}
      >
        {decorativeNode}
        {isMulti && (
          <Box className={checkboxWrapper}>
            <IconFontAwesome
              icon={faCheck}
              size="xs"
              className={checkboxIcon}
            />
            <Box
              as="input"
              type="checkbox"
              readOnly
              tabIndex={-1}
              checked={isDropdownItemSelected}
              style={{ display: "none" }}
            />
          </Box>
        )}
        <Box flexShrink="0">
          {label}
          {description}
        </Box>
      </Box>
    );
  }
);
