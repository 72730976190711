import React from "react";

import type { SingleMetaObject } from ".";
import type { MetaHTMLAttributes } from "react";

export function RenderSeoMetadata({ tag, ...rest }: SingleMetaObject) {
  switch (tag) {
    case "title":
      return <title>{rest.content}</title>;

    case "link":
      return <link {...rest} />;

    case "meta":
      return <meta {...(rest as MetaHTMLAttributes<HTMLMetaElement>)} />;

    default:
      return null;
  }
}
