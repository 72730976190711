import type { SingleMetaObject } from ".";

export interface BuildTwitterTagsArgs {
  fullUrl: string;
  title: string;
  description: string;
  imageUrl: string;
}

export const buildTwitterTags = ({
  fullUrl,
  title,
  description,
  imageUrl,
}: BuildTwitterTagsArgs): Array<SingleMetaObject> => {
  return [
    { tag: "meta", property: "twitter:card", content: "summary_large_image" },
    { tag: "meta", property: "twitter:url", content: fullUrl },
    { tag: "meta", property: "twitter:title", content: title },
    { tag: "meta", property: "twitter:description", content: description },
    { tag: "meta", property: "twitter:image", content: imageUrl },
  ];
};
