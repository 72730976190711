import React from "react";

// import  { getSprinkles } from "~styles/getSprinkles.css";
import { StoryblokComponent } from "~components/StoryblokComponent";

import type { RenderOptions } from "storyblok-rich-text-react-renderer";

export const RichTextBlokResolver: RenderOptions["defaultBlokResolver"] =
  // eslint-disable-next-line react/function-component-definition
  (name, props) => {
    return (
      <StoryblokComponent
        // className={getSprinkles({ marginBottom: "spacing3" })}
        component={name}
        {...props}
      />
    );
  };
