import { useMemo } from "react";

import { getCurrentLanguageDropdownItem } from "./_getCurrentLanguageDropdownItem";
import { getLanguagesForLanguageSelect } from "./_getLanguagesForLanguageSelect";

import type { LanguageSelectItem } from ".";
import type {
  StoryblokBlok,
  StoryblokEntryAlternate,
} from "~types/storyblok.types";

export interface UseLanguageSelectStateArgs {
  alternates: Array<StoryblokEntryAlternate>;
  languages: Array<LanguageSelectItem & StoryblokBlok>;
  currentLanguage: string;
}

export const useLanguageSelectState = ({
  alternates,
  languages,
  currentLanguage: initialCurrentLanguage,
}: UseLanguageSelectStateArgs) => {
  /** Cache expensive transformation of languages into `DropdownItems */
  const languageDropdownItems = useMemo(() => {
    return getLanguagesForLanguageSelect({
      languages,
      alternates,
    });
  }, [alternates, languages]);

  const currentLanguageDropdownItem = useMemo(() => {
    return (
      languageDropdownItems &&
      getCurrentLanguageDropdownItem({
        languageDropdownItems,
        currentLanguage: initialCurrentLanguage,
      })
    );
  }, [initialCurrentLanguage, languageDropdownItems]);

  return {
    languageDropdownItems,
    currentLanguageDropdownItem,
  };
};
