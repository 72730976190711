import React from "react";

import { checkHasLength } from "~utils/__deprecated__/checkHasLength";

import { SelectSingle } from "../Select/SelectSingle";
import { useLanguageSelectState } from "./_useLanguageSelectState";

import type { GetSprinklesArgs } from "~styles/getSprinkles.css";
import type {
  StoryblokBlok,
  StoryblokEntryAlternate,
  StoryblokFieldLink,
} from "~types/storyblok.types";

export interface LanguageSelectItem {
  isCurrent?: boolean;
  link: StoryblokFieldLink;
  title: string;
}

export interface SelectLanguageProps extends GetSprinklesArgs {
  alternates: Array<StoryblokEntryAlternate>;
  currentLanguage: string;
  languages: Array<LanguageSelectItem & StoryblokBlok>;
}

/**
 * Renders a `Select` with a list of links to alternate language pages.
 * Uses an array of alternate pages to offer the best option to users.
 * Detects user's location via their Timezone, and if there is an alternate
 * in a subset of languages (German, French, Spanish), renders a tooltip notifying the user.
 */
export function SelectLanguage({
  alternates,
  languages,
  currentLanguage: initialCurrentLanguage,
  ...rest
}: SelectLanguageProps) {
  /**
   * Handle checking user's location via timezone, finding a recommended
   * alternate page, and choosing a UI prompt to show in the tooltip.
   */
  const { currentLanguageDropdownItem, languageDropdownItems } =
    useLanguageSelectState({
      alternates,
      languages,
      currentLanguage: initialCurrentLanguage,
    });

  if (!languageDropdownItems || !checkHasLength(languageDropdownItems))
    return null;

  return (
    <SelectSingle
      {...rest}
      paddingLeft="spacing1"
      initialSelectedItem={currentLanguageDropdownItem}
      id="language-select"
      name="language-select"
      inputAppearance="transparent"
      // placeholder={currentLanguageDropdownItem?.value || initialCurrentLanguage}
      items={languageDropdownItems}
      decorativeNodeLeft={currentLanguageDropdownItem?.decorativeNode}
      iconRight="disabled"
    />
  );
}
