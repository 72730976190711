import React, { useMemo } from "react";

import { RenderSeoMetadata } from "./_RenderSeoMetadata";
import { getSeoMetadata } from "./_getSeoMetadata";

import type { EnabledLanguageIsoCode } from "~config/ENABLED_LANGUAGES.config";
import type {
  PageMetadata,
  StoryblokEntryAlternate,
  StoryblokGatsbyImage,
} from "~types/storyblok.types";

export interface SeoMetadataProps
  extends Pick<PageMetadata, "description" | "prefix" | "title"> {
  alternates?: Array<StoryblokEntryAlternate>;
  author?: string;
  fullSlug: string;
  language: EnabledLanguageIsoCode;
  metaImage?: StoryblokGatsbyImage;
}
export interface SingleMetaObject {
  content?: string;
  href?: string;
  hrefLang?: EnabledLanguageIsoCode | "x-default";
  name?: string;
  property?: string;
  rel?: "alternate" | "x-default" | "canonical";
  tag: "link" | "meta" | "title";
}
export interface TagLinkHrefLangAlternate extends SingleMetaObject {
  href: string;
  hrefLang: EnabledLanguageIsoCode | "x-default";
  rel: "alternate";
  tag: "link";
}
export interface SeoMetadataSchemaShape {
  localizationTags: Array<TagLinkHrefLangAlternate>;
}

export function SeoMetadata({
  description,
  title,
  prefix,
  author,
  language,
  metaImage,
  alternates,
  fullSlug,
}: SeoMetadataProps) {
  const structuredSeoMetadata: Array<SingleMetaObject> = useMemo(() => {
    return getSeoMetadata({
      description,
      fullSlug,
      language,
      title,
      alternates,
      author,
      metaImage,
      prefix,
    });
  }, [
    alternates,
    author,
    description,
    fullSlug,
    language,
    metaImage,
    prefix,
    title,
  ]);

  if (!Array.isArray(structuredSeoMetadata)) {
    return null;
  }

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {structuredSeoMetadata?.map(({ tag, name, property, ...rest }, index) => {
        return (
          <RenderSeoMetadata
            tag={tag}
            name={name}
            property={property}
            /** Data is static, can key by index */
            // eslint-disable-next-line react/no-array-index-key
            key={`${tag}-${name || property}-${index}`}
            {...rest}
          />
        );
      })}
    </>
  );
}
