import React, { forwardRef, useCallback, useMemo, useState } from "react";

import { extractAtomsFromProps } from "@dessert-box/core";
import { faAngleDown } from "@fortawesome/sharp-regular-svg-icons/faAngleDown";
import { useCombobox } from "downshift";

import { getSprinkles } from "~styles/getSprinkles.css";

import { Box } from "~components/Box";
import { Input } from "~components/Input";
import { Label } from "~components/Label";

import { DropdownMenu } from "../common/_DropdownMenu";
import { downshiftStateReducer } from "../common/_downshiftStateReducer";
import { getDefaultHighlightedIndex } from "../common/_getDefaultHighlightedIndex";
import { getFilteredDropdownItems } from "../common/_getFilteredDropdownItems";
import { getIsSelected } from "../common/_getIsSelected";

import type { UseComboboxStateChange } from "downshift";
import type { ReactNode, Ref } from "react";
import type { IconFontAwesomeProps } from "~components/IconFontAwesome";
import type { InputProps } from "~components/Input";
import type { VariantInputAppearanceEnum } from "~components/Input/styles.css";
import type { VariantUiSizeEnum } from "~styles/common/variantUiScale.css";
import type { GetSprinklesArgs } from "~styles/getSprinkles.css";
import type { DropdownItemShape } from "~types/global.types";

/**
 * ------------------------------------------------------------------------------
 * Util function for transforming a dropdown item into a string (for use as a `value` for {@link Input}).
 * Can be overridden by passing a custom `itemToString` prop to the `SelectSingle` component.
 * ------------------------------------------------------------------------------
 */

const defaultItemToString = (item: DropdownItemShape | null) => {
  return item?.label || "";
};

export interface SelectSingleProps extends GetSprinklesArgs {
  id: string;
  items: Array<DropdownItemShape>;
  name: string;
  placeholder?: string;

  disabled?: boolean;
  errorMessage?: string;
  iconLeft?: IconFontAwesomeProps["icon"];
  iconRight?: IconFontAwesomeProps["icon"] | "disabled";
  decorativeNodeLeft?: ReactNode;
  initialHighlightedItem?: DropdownItemShape;
  initialSelectedItem?: DropdownItemShape | null;
  inputProps?: Partial<InputProps>;
  invalid?: boolean;
  isFilterable?: boolean;
  isMulti?: true;
  isOpen?: boolean;
  itemToString?: (item: DropdownItemShape | null) => string;
  label?: string;
  labelClassName?: string;
  onIsOpenChange?: (changes: UseComboboxStateChange<DropdownItemShape>) => void;
  onSelectedItemChange?: (
    changes: UseComboboxStateChange<DropdownItemShape>
  ) => void;
  size?: VariantUiSizeEnum;
  inputAppearance?: VariantInputAppearanceEnum;
}

/** Accessible select component, supports multi & single modes. */
export const SelectSingle = forwardRef(
  (
    {
      disabled,
      iconLeft,
      iconRight,
      decorativeNodeLeft,
      id,
      initialHighlightedItem,
      initialSelectedItem,
      inputProps,
      invalid,
      isFilterable,
      isOpen: controlledIsOpen,
      items,
      itemToString = defaultItemToString,
      label,
      labelClassName,
      name,
      onIsOpenChange,
      onSelectedItemChange,
      placeholder,
      size,
      inputAppearance,
      ...rest
    }: SelectSingleProps,
    ref: Ref<HTMLInputElement>
  ) => {
    const { atomProps: inputAtomProps, otherProps: inputOtherProps } =
      extractAtomsFromProps(inputProps, getSprinkles);

    const [inputValue, setInputValue] = useState("");

    /**
     * ------------------------------------------------------------------------------
     * When `isFilterable` is true, we need to filter the items based on the input value.
     * ------------------------------------------------------------------------------
     */

    const filteredItems = useMemo(() => {
      if (!items || !isFilterable) return items;

      return getFilteredDropdownItems({ items, inputValue });
    }, [items, isFilterable, inputValue]);

    /** Initialise downshift `useCombobox` hook */
    const {
      getInputProps,
      getItemProps,
      getMenuProps,
      highlightedIndex,
      selectedItem,
      selectItem,
      isOpen,
      toggleMenu,
    } = useCombobox({
      defaultHighlightedIndex: getDefaultHighlightedIndex({
        initialHighlightedItem,
        items,
      }),
      initialSelectedItem,
      isOpen: controlledIsOpen,
      items: filteredItems,
      itemToString,
      onIsOpenChange,
      onSelectedItemChange,
      onStateChange({
        inputValue: newInputValue,
        type,
        selectedItem: newSelectedItem,
      }) {
        switch (type) {
          case useCombobox.stateChangeTypes.InputKeyDownEnter:
          case useCombobox.stateChangeTypes.ItemClick:
          case useCombobox.stateChangeTypes.InputBlur:
            if (newSelectedItem) {
              selectItem(newSelectedItem);
              setInputValue(newSelectedItem.label);
            }
            break;

          case useCombobox.stateChangeTypes.InputChange:
            if (typeof newInputValue !== "undefined") {
              setInputValue(newInputValue);
            }

            break;
          default:
            break;
        }
      },
      stateReducer: (state, actionAndChanges) => {
        return downshiftStateReducer(state, actionAndChanges, {});
      },
    });

    const getIsItemSelected = useCallback(
      (item: DropdownItemShape) => {
        return getIsSelected({
          item,
          selectedItem,
        });
      },
      [selectedItem]
    );

    return (
      <Box position="relative" {...rest}>
        {label && (
          <Label htmlFor={id} label={label} className={labelClassName} />
        )}

        <Input
          size={size}
          appearance={inputAppearance}
          iconLeft={iconLeft}
          decorativeNodeLeft={decorativeNodeLeft}
          iconRight={iconRight === "disabled" ? undefined : faAngleDown}
          readOnly={!isFilterable}
          invalid={invalid}
          {...inputAtomProps}
          {...(getInputProps &&
            getInputProps({
              ...inputOtherProps,
              disabled,
              id,
              name,
              onClick: toggleMenu,
              placeholder,
              ref,
              value: inputValue,
            }))}
        />

        <DropdownMenu
          getIsItemSelected={getIsItemSelected}
          getItemProps={getItemProps}
          getMenuProps={getMenuProps}
          highlightedIndex={highlightedIndex}
          isOpen={isOpen}
          items={filteredItems}
          size={size}
        />
      </Box>
    );
  }
);
