import type { PageMetadata } from "~types/storyblok.types";

const checkIfContainsBrandName = (string?: string | null | undefined) => {
  return string?.toLowerCase().includes("truelayer");
};

/**
 * - Takes a {@link PageMetadata["prefix"]} & {@link PageMetadata["title"]} and returns
 * a human readable, SEO friendly string.
 * - Optionally adds the brand name "TrueLayer" if
 * the inputs don't already contain it.
 */
export const getFullTitle = ({
  prefix,
  title,
}: Pick<PageMetadata, "prefix" | "title">) => {
  const shouldAddBrandName =
    !checkIfContainsBrandName(prefix) && !checkIfContainsBrandName(title);

  const fullTitle = prefix ? `${prefix.trim()}: ${title.trim()}` : title.trim();

  if (shouldAddBrandName) {
    return `${fullTitle} | TrueLayer`;
  }

  return fullTitle;
};
