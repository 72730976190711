import type { SingleMetaObject } from ".";

export interface BuildGenericTagsArgs {
  title: string;
  description: string;
  imageUrl: string;
}

export const buildGenericTags = ({
  // fullUrl,
  title,
  description,
  imageUrl,
}: BuildGenericTagsArgs): Array<SingleMetaObject> => {
  return [
    { tag: "title", content: title },
    { tag: "meta", name: "theme-color", content: "#ffffff" },
    { tag: "meta", name: "title", content: title },
    { tag: "meta", name: "description", content: description },
    { tag: "meta", property: "image", content: imageUrl },
  ];
};
