import { getEnglishVersionSlug } from "./_getEnglishVersionSlug";
import { transformHrefLangAlternate } from "./_transformHrefLangAlternate";

import type { SingleMetaObject } from ".";
import type { EnabledLanguageIsoCode } from "~config/ENABLED_LANGUAGES.config";
import type { StoryblokEntryAlternate } from "~types/storyblok.types";

export interface BuildLocalizationTagsArgs {
  alternates?: Array<StoryblokEntryAlternate>;
  origin: string;
  fullUrl: string;
  fullSlug: string;
  language: EnabledLanguageIsoCode | "x-default";
}

export const buildLocalizationTags = ({
  alternates,
  origin,
  fullSlug,
  fullUrl,
  language = "en-gb",
}: BuildLocalizationTagsArgs): Array<SingleMetaObject> => {
  const tags: Array<SingleMetaObject> = [
    {
      tag: "link",
      rel: "canonical",
      href: fullUrl,
    },
  ];

  if (Array.isArray(alternates) && alternates.length > 0) {
    tags.push(
      /** Ensure `en-gb` version of current page is marked as "default" version  */
      {
        tag: "link",
        href: getEnglishVersionSlug({
          alternates,
          fullSlug,
          language,
          origin,
        }),
        hrefLang: "x-default",
        rel: "alternate",
      },

      /** Ensure self-referencing href-lang tag  */
      {
        tag: "link",
        href: fullUrl,
        hrefLang: language,
        rel: "alternate",
      }
    );
  }

  alternates?.forEach((alternate) => {
    tags.push(transformHrefLangAlternate({ alternate, origin }));
  });

  return tags;
};
