import { checkHasLength } from "~utils/__deprecated__/checkHasLength";
import { LANGUAGE_REGEX } from "~utils/regex/LANGUAGE_REGEX";

import { SvgFlagIcon } from "~components/SvgFlagIcon";

import type { DropdownItemShape } from "~types/global.types";
import type {
  StoryblokBlok,
  StoryblokEntryAlternate,
  StoryblokFieldLink,
} from "~types/storyblok.types";

interface LanguageBlok {
  link: StoryblokFieldLink;
  title: string;
  isCurrent?: boolean;
}

export interface TransformLanguagesForSelectArgs {
  languages: Array<LanguageBlok & StoryblokBlok>;
  alternates: Array<StoryblokEntryAlternate>;
}

export function getLanguagesForLanguageSelect({
  languages,
  alternates,
}: TransformLanguagesForSelectArgs): Array<DropdownItemShape> | undefined {
  const hasLanguages = languages && checkHasLength(languages);
  if (!hasLanguages) return undefined;

  return languages.reduce(
    (accumulator: Array<DropdownItemShape>, { link, title }) => {
      if (!link || !title) return accumulator;

      /** Extract the actual page url from the current language's link object */
      const { full_slug } = link.story || {};
      if (!full_slug) return accumulator;

      /**
       * If no alternate found for this language, just use the full_slug
       * for the current language
       */
      let linkSlug = full_slug;

      /** Test urls for iso country code prefix */
      const [validatedLanguagePrefix] = full_slug.match(LANGUAGE_REGEX) || [];
      if (!validatedLanguagePrefix) return accumulator;

      const matchingAlternateVersion = alternates?.find((alternate) => {
        return alternate.full_slug?.startsWith(validatedLanguagePrefix);
      });

      if (matchingAlternateVersion) {
        linkSlug = matchingAlternateVersion.full_slug;
      }

      /**
       * Transform language prefix into just country code
       * e.g. `en-gb/` —> `GB`
       * e.g. `en-au/` —> `AU`
       */
      const countryCode =
        validatedLanguagePrefix?.split("-")[1]?.split("/")[0]?.toUpperCase() ||
        "";

      accumulator.push({
        label: countryCode,
        decorativeNode: SvgFlagIcon({ maxWidth: "spacing3", countryCode }),
        value: countryCode,
        link: linkSlug,
      });

      return accumulator;
    },
    []
  );
}
