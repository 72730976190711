import React from "react";

import { extractAtomsFromProps } from "@dessert-box/core";
import clsx from "clsx";

import { getSprinkles } from "~styles/getSprinkles.css";

import * as styles from "./styles.css";

import type { GetSprinklesArgs } from "~styles/getSprinkles.css";

/** We don't allow setting height, only width. as the aspect ratio is fixed to 4:3 */
export interface SvgFlagIconProps extends Omit<GetSprinklesArgs, "height"> {
  countryCode: string;
}

export function SvgFlagIcon({
  countryCode,
  width = "spacing4",
  ...rest
}: SvgFlagIconProps) {
  const { atomProps, otherProps } = extractAtomsFromProps(rest, getSprinkles);

  const classNames = clsx(
    styles.flagIcon,
    getSprinkles({
      ...atomProps,
      width,
      flexShrink: "0",
    })
  );

  const countryCodeLowerCase = countryCode?.toLowerCase();

  if (!countryCodeLowerCase) return null;

  return (
    <svg
      role="img"
      className={classNames}
      viewBox="0 0 640 480"
      preserveAspectRatio="xMidYMid slice"
      data-testid="flag-icon"
      {...otherProps}
    >
      <use
        xlinkHref={`/images/flags/${countryCodeLowerCase}.svg#flag-icon-css-${countryCodeLowerCase}`}

        // xlinkHref={`/images/flags/${countryCodeLowerCase}.svg`}
      />
    </svg>
  );
}
