import React from "react";

import { StoryblokEditable } from "~components/StoryblokEditable";

import { STORYBLOK_COMPONENT_MAP } from "./STORYBLOK_DYNAMIC_COMPONENTS_MAP";

import type { StoryblokBlok } from "~types/storyblok.types";

export function StoryblokComponent({ component, ...rest }: StoryblokBlok) {
  if (!component || !STORYBLOK_COMPONENT_MAP[component]) {
    console.warn(`[dynamic-component]: Missing "${component}"}`);

    return null;
  }
  const Component = STORYBLOK_COMPONENT_MAP[component];

  if (!Component) return null;

  return (
    <StoryblokEditable component={component} {...rest}>
      <Component {...rest} />
    </StoryblokEditable>
  );
}
