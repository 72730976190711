import React from "react";

import clsx from "clsx";
import Balancer from "react-wrap-balancer";
import {
  MARK_ITALIC,
  MARK_LINK,
  MARK_STYLED,
  render,
} from "storyblok-rich-text-react-renderer";

import { Box } from "~components/Box";

import { RichTextItalic } from "./RichTextItalic";
import { RichTextLink } from "./RichTextLink";
import { RichTextStyled } from "./RichTextStyled";
import * as styles from "./styles.css";

import type { RenderOptions } from "storyblok-rich-text-react-renderer";
import type { BoxProps } from "~components/Box";
import type { StoryblokComponentRichText } from "~types/storyblok.types";

export interface StoryblokRichTextProps
  extends BoxProps,
    RenderOptions,
    StoryblokComponentRichText {
  className?: string;
  isTextBalanced?: boolean;
}

function RichTextInner({
  text,
  defaultBlokResolver,
  blokResolvers,
  defaultStringResolver,
  textResolver,
}: Omit<StoryblokRichTextProps, "className" | "isTextBalanced">) {
  return (
    <>
      {render(text, {
        markResolvers: {
          [MARK_LINK]: RichTextLink,
          [MARK_ITALIC]: RichTextItalic,
          [MARK_STYLED]: RichTextStyled,
        },
        defaultBlokResolver,
        blokResolvers,
        defaultStringResolver,
        textResolver,
      })}
    </>
  );
}

/**
 * Renders rich text set in storyblok to html and resolves custom marks and components.
 */
export function StoryblokRichText({
  text,
  className: userClassName,
  defaultBlokResolver,
  blokResolvers,
  rowGap = "spacing3",
  defaultStringResolver,
  textResolver,
  as = "div",
  style,
  isTextBalanced,
  ...rest
}: StoryblokRichTextProps) {
  if (!text) return null;

  return (
    <Box
      {...rest}
      as={as}
      style={style}
      display="flex"
      flexDirection="column"
      rowGap={rowGap}
      className={clsx(styles.richTextContainer, userClassName)}
    >
      {isTextBalanced ? (
        <Balancer>
          <RichTextInner
            text={text}
            defaultBlokResolver={defaultBlokResolver}
            blokResolvers={blokResolvers}
            defaultStringResolver={defaultStringResolver}
            textResolver={textResolver}
          />
        </Balancer>
      ) : (
        <RichTextInner
          text={text}
          defaultBlokResolver={defaultBlokResolver}
          blokResolvers={blokResolvers}
          defaultStringResolver={defaultStringResolver}
          textResolver={textResolver}
        />
      )}
    </Box>
  );
}
