import type { SingleMetaObject } from ".";

export interface BuildOgTagsArgs {
  fullUrl: string;
  title: string;
  description: string;
  imageUrl: string;
}

export const buildOgTags = ({
  fullUrl,
  title,
  description,
  imageUrl,
}: BuildOgTagsArgs): Array<SingleMetaObject> => {
  return [
    { tag: "meta", property: "og:url", content: fullUrl },
    { tag: "meta", property: "og:title", content: title },
    { tag: "meta", property: "og:description", content: description },
    { tag: "meta", property: "og:image", content: imageUrl },
    { tag: "meta", property: "og:type", content: "website" },
    // { tag: "meta", property: "og:owners", content: undefined },
  ];
};
