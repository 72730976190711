import React, { useEffect } from "react";

import clsx from "clsx";
import { animate } from "motion";

import {
  ANIMATED,
  getMotionSelector,
  getSingleElemByMotionAttr,
} from "~utils/motion_one/motion_attribute_selectors";

import { Box } from "~components/Box";

import * as styles from "./styles.css";

import type { BoxProps } from "~components/Box";
import type { VariantBackgroundColorEnum } from "~styles/common/variant.backgroundColor.css";

export interface SliderIndicatorProps extends BoxProps {
  title?: string;
  shape: "line";
  isCurrent: boolean;
  animationDuration?: number;
  isPaused: boolean;
  titleColorOverride?: VariantBackgroundColorEnum;
}

function Line({
  title,
  onClick,
  isCurrent,
  animationDuration = 3,
  isPaused,
  className,
}: Partial<SliderIndicatorProps>) {
  const refContainer = React.useRef<HTMLDivElement>(null);
  const selector = getMotionSelector(ANIMATED.slider);

  useEffect(() => {
    if (!isCurrent) return () => {};

    const line = getSingleElemByMotionAttr<SVGAElement>(
      refContainer,
      ANIMATED.slider
    );
    if (!line) return () => {};

    const bg = getSingleElemByMotionAttr<SVGAElement>(
      refContainer,
      ANIMATED.sliderBackground
    );
    if (!bg) return () => {};

    const animation = animate(
      line,
      {
        width: [0, "100%", "100%"],
        left: [0, 0, "100%"],
      },
      {
        duration: animationDuration,
        easing: "linear",
      }
    );

    if (isPaused) {
      animation.pause();
    } else {
      animation.play();
    }

    return () => {
      animation.cancel();
    };
  }, [isCurrent, animationDuration, selector, isPaused]);

  return (
    <Box
      ref={refContainer}
      marginTop="spacing3"
      display="flex"
      flexDirection="column"
      padding="spacing1"
      justifyContent="center"
      alignItems="center"
      onClick={onClick}
      fontSize="body_md"
      fontWeight="bold"
      gap="spacing1"
      className={clsx(className, styles.getLineContainerStyles({ isCurrent }))}
    >
      <Box
        className={styles.getLineIndicatorStyles({ role: "background" })}
        data-motion={ANIMATED.sliderBackground}
        overflow="hidden"
      >
        <Box
          className={styles.getLineIndicatorStyles({ role: "progress" })}
          data-motion={ANIMATED.slider}
        />
      </Box>
      {title}
    </Box>
  );
}

const IndicatorsMap = {
  line: Line,
};

export function SliderIndicator({
  shape,
  title,
  isCurrent,
  onClick,
  animationDuration,
  isPaused,
  className: indicatorClassName,
  titleColorOverride,
}: SliderIndicatorProps) {
  const Indicator = IndicatorsMap[shape];

  return (
    <Indicator
      className={clsx(indicatorClassName, styles.sliderIndicator)}
      {...{
        title,
        isCurrent,
        onClick,
        animationDuration,
        isPaused,
        titleColorOverride,
      }}
    />
  );
}
