import type { DropdownItemShape } from "~types/global.types";

interface GetDisplayValueArgs {
  originalValue: string;
  selectedItems?: Array<DropdownItemShape>;
}

export const getDisplayValue = ({
  originalValue,
  selectedItems,
}: GetDisplayValueArgs) => {
  if (!selectedItems) return originalValue;

  const { length } = selectedItems;
  const { label } = selectedItems[length - 1] ?? {};

  if (!label) return originalValue;

  if (length < 2) return label;

  return `${label}, +${length - 1}`;
};
