import type { DropdownItemShape } from "~types/global.types";

export interface GetCurrentLanguageDropdownItemArgs {
  /** Array of `DropdownItemShape`s with language information in them */
  languageDropdownItems: Array<DropdownItemShape>;
  /** Iso region language code, e.g. "en-gb" */
  currentLanguage: string;
}

/**
 * Given a list of languages as `DropdownItemShape`s, and a `currentLanguage` as an ISO
 * region-language string (e.g. `en-gb`), uses just the region (e.g `gb`) to find
 * the corresponding `DropdownItemShape`
 */
export const getCurrentLanguageDropdownItem = ({
  languageDropdownItems,
  currentLanguage,
}: GetCurrentLanguageDropdownItemArgs) => {
  return languageDropdownItems.find((dropdownItem) => {
    return dropdownItem?.value.toLowerCase() === currentLanguage.split("-")[1];
  });
};
