import React from "react";

import { Link } from "~components/Link";

import * as styles from "./styles.css";

import type { ReactNode } from "react";
import type { StoryblokFieldLink } from "~types/storyblok.types";

export function RichTextLink(children: ReactNode, attrs: StoryblokFieldLink) {
  return (
    <Link link={attrs} className={styles.link}>
      {children}
    </Link>
  );
}
