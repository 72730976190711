import { createUrlPathFromArray } from "~utils/path_utils/createUrlPathFromArray";
import { removeDefaultLanguagePrefix } from "~utils/path_utils/removeDefaultLanguagePrefix";
import { getLanguageForStoryblokEntry } from "~utils/storyblok/getLanguageForStoryblokEntry";

import type { TagLinkHrefLangAlternate } from ".";
import type { StoryblokEntryAlternate } from "~types/storyblok.types";

export interface TransformTagLinkHrefLangAlternateArgs {
  alternate: StoryblokEntryAlternate;
  origin: string;
}

export const transformHrefLangAlternate = ({
  alternate,
  origin,
}: TransformTagLinkHrefLangAlternateArgs): TagLinkHrefLangAlternate => {
  /** Ensure `en-gb` prefix removed from slugs if present */
  const cleanSlug = removeDefaultLanguagePrefix(alternate.full_slug);

  return {
    tag: "link",
    rel: "alternate",
    href: createUrlPathFromArray([origin, cleanSlug], {
      shouldHaveTrailingSlash: true,
    }),
    hrefLang: getLanguageForStoryblokEntry(alternate),
  };
};
