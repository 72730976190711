import { createUrlPathFromArray } from "~utils/path_utils/createUrlPathFromArray";

import { SITE_URL } from "~config/SITE_URL.config";

import { buildGenericTags } from "./_buildGenericTags";
import { buildLocalizationTags } from "./_buildLocalizationTags";
import { buildOgTags } from "./_buildOgTags";
import { buildTwitterTags } from "./_buildTwitterTags";
import { getFullTitle } from "./_getFullTitle";

import type { SeoMetadataProps, SingleMetaObject } from ".";
import type {
  PageMetadata,
  StoryblokEntryAlternate,
  StoryblokGatsbyImage,
} from "~types/storyblok.types";

export interface TransformSeoMetadataArgs extends PageMetadata {
  alternates?: Array<StoryblokEntryAlternate>;
  author?: string;
  fullSlug?: string;
  language?: string;
  metaImage?: StoryblokGatsbyImage;
}

export const getSeoMetadata = ({
  alternates,
  description,
  fullSlug,
  language,
  prefix,
  title = "TrueLayer",
  metaImage,
}: SeoMetadataProps): Array<SingleMetaObject> => {
  const fullTitle = getFullTitle({ title, prefix });

  const fullUrl = createUrlPathFromArray([SITE_URL, fullSlug], {
    shouldHaveTrailingSlash: true,
  });

  const metaImagePath =
    metaImage?.childImageSharp.original.src || "/images/logo/TrueLayer.png";
  const metaImageUrl = createUrlPathFromArray([SITE_URL, metaImagePath]);

  return [
    ...buildGenericTags({
      title: fullTitle,
      description,
      imageUrl: metaImageUrl,
    }),
    ...buildLocalizationTags({
      fullSlug,
      fullUrl,
      language,
      origin: SITE_URL,
      alternates,
    }),
    ...buildTwitterTags({
      fullUrl,
      title: fullTitle,
      description,
      imageUrl: metaImageUrl,
    }),
    ...buildOgTags({
      fullUrl,
      title: fullTitle,
      description,
      imageUrl: metaImageUrl,
    }),
  ];
};
