import React, { forwardRef } from "react";

import { extractAtomsFromProps } from "@dessert-box/core";
import clsx from "clsx";

import { getSprinkles } from "~styles/getSprinkles.css";

import { checkHasLength } from "~utils/__deprecated__/checkHasLength";

import { Box } from "~components/Box";

import { DropdownItem } from "./_DropdownItem";
import * as styles from "./styles.css";

import type {
  UseComboboxPropGetters,
  UseMultipleSelectionActions,
  UseMultipleSelectionGetSelectedItemPropsOptions,
} from "downshift";
import type { LegacyRef } from "react";
import type { VariantUiSizeEnum } from "~styles/common/variantUiScale.css";
import type { GetSprinklesArgs } from "~styles/getSprinkles.css";
import type { DropdownItemShape } from "~types/global.types";

/**
 * ------------------------------------------------------------------------------
 * Util function to get props for individual dropdown items
 * May conditionally call `getItemProps` or `getSelectedItemProps` depending on
 * whether `isMulti` is true, and whether the item is selected or not
 * ------------------------------------------------------------------------------
 */

interface getDropdownItemPropsArgs
  extends Pick<
    DropdownMenuProps,
    "getItemProps" | "getSelectedItemProps" | "removeSelectedItem"
  > {
  item: DropdownItemShape;
  isItemSelected?: boolean;
  [key: string]: unknown;
}

export const getDropdownItemProps = ({
  isItemSelected,
  getItemProps,
  getSelectedItemProps,
  removeSelectedItem,
  item,
  isMulti,
  ...rest
}: getDropdownItemPropsArgs) => {
  if (isMulti && getSelectedItemProps) {
    return isItemSelected
      ? {
          ...getItemProps({
            item,
            ...rest,
          }),
          ...getSelectedItemProps({
            selectedItem: item,
            onClick: (e) => {
              e.stopPropagation();
              if (removeSelectedItem) {
                removeSelectedItem(item);
              }
            },
          }),
        }
      : getItemProps({ item, ...rest });
  }

  return getItemProps({ item });
};

/**
 * ------------------------------------------------------------------------------
 * Renders a dropdown menu for use with `SelectSingle` or `SelectMultiple`
 * ------------------------------------------------------------------------------
 */

export interface DropdownMenuProps extends GetSprinklesArgs {
  /** Required props  */
  getIsItemSelected: (item: DropdownItemShape) => boolean;
  getItemProps: UseComboboxPropGetters<DropdownItemShape>["getItemProps"];
  getMenuProps: UseComboboxPropGetters<DropdownItemShape>["getMenuProps"];
  isOpen: boolean;
  items: Array<DropdownItemShape>;

  /** Optional props  */
  getSelectedItemProps?: (
    options: UseMultipleSelectionGetSelectedItemPropsOptions<DropdownItemShape>
  ) => { [key: string]: unknown };
  isMulti?: boolean;
  highlightedIndex?: number | undefined;
  removeSelectedItem?: UseMultipleSelectionActions<DropdownItemShape>["removeSelectedItem"];
  size?: VariantUiSizeEnum;
}

export const DropdownMenu = forwardRef(
  (
    {
      getIsItemSelected,
      getItemProps,
      getMenuProps,
      getSelectedItemProps,
      highlightedIndex,
      removeSelectedItem,
      isMulti,
      isOpen,
      items,
      size = "md",
      ...rest
    }: DropdownMenuProps,
    ref
  ) => {
    const dropdownWrapperStyles = clsx(styles.dropdownListWrapper);

    const { atomProps, otherProps } = extractAtomsFromProps(rest, getSprinkles);

    /**
     * Note: `DropdownMenu` *must* not be in a conditional render, or
     * downshift's `getMenuProps` will be unable to apply a ref and throw an error
     */
    return (
      <Box
        className={clsx(styles.dropdownWrapperOuter, {
          [styles.dropdownWrapperClosed]: !isOpen,
        })}
      >
        <Box
          className={dropdownWrapperStyles}
          {...atomProps}
          {...(getMenuProps &&
            getMenuProps({
              ...otherProps,
              ref: ref as LegacyRef<HTMLElement>,
            }))}
        >
          {checkHasLength(items) &&
            items.map((item, index) => {
              if (!item.label) {
                return null;
              }

              const isItemSelected =
                getIsItemSelected && getIsItemSelected(item);

              return (
                <DropdownItem
                  item={item}
                  isMulti={isMulti}
                  isHighlighted={highlightedIndex === index}
                  key={item.label}
                  isDropdownItemSelected={isItemSelected}
                  size={size}
                  {...getDropdownItemProps({
                    isItemSelected,
                    index,
                    getItemProps,
                    getSelectedItemProps,
                    item,
                    isMulti,
                    removeSelectedItem,
                  })}
                />
              );
            })}
        </Box>
      </Box>
    );
  }
);
