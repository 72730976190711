/* eslint-disable react/no-danger */
import React, { useMemo } from "react";

import { clsx } from "clsx";
import DOMPurify from "dompurify";

import { Box } from "~components/Box";

import * as styles from "./styles.css";

import type { BoxProps } from "~components/Box";

interface SanitizeHtmlWrapperProps extends BoxProps {
  /** Class name to apply to the outermost element */
  userClassName?: string;
  /** Html string, potentially with unsafe code in it. */
  htmlString: string;
}

/** Converts potentially unsafe html into sanitised html and renders it. */
export function SanitizeHtmlWrapper({
  className: userClassName,
  htmlString,
  ...rest
}: SanitizeHtmlWrapperProps) {
  const sanitizedHtml = useMemo(() => {
    if (typeof window !== "undefined") {
      const parsedHtml = new DOMParser().parseFromString(
        htmlString,
        "text/html"
      );
      if (parsedHtml.documentElement.textContent) {
        return DOMPurify.sanitize(parsedHtml.documentElement.textContent, {
          SANITIZE_DOM: true,
          FORCE_BODY: true,
          ALLOWED_ATTR: [""],
          IN_PLACE: true,
        }).replace(/<(div|span)>&nbsp;<\/(div|span)>/g, "");
      }

      return null;
    }

    return null;
  }, [htmlString]);

  if (sanitizedHtml) {
    return (
      <Box
        className={clsx(userClassName, styles.sanitizedMarkup)}
        dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
        {...rest}
      />
    );
  }

  return null;
}
