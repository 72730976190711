import { createUrlPathFromArray } from "~utils/path_utils/createUrlPathFromArray";
import { ensureTrailingSlash } from "~utils/path_utils/ensureTrailingSlash";
import { removeDefaultLanguagePrefix } from "~utils/path_utils/removeDefaultLanguagePrefix";

import type { StoryblokEntryAlternate } from "~types/storyblok.types";

export interface GetEnglishVersionSlugArgs {
  origin?: string;
  alternates?: Array<StoryblokEntryAlternate>;
  language?: string;
  fullSlug?: string;
}

export const getEnglishVersionSlug = ({
  origin,
  alternates,
  language,
  fullSlug,
}: GetEnglishVersionSlugArgs) => {
  if (alternates && alternates.length > 0) {
    // The english alternate is not returned in the alternates response
    // We need this for a self-referring alternate link
    if (language === "en-gb") {
      const englishVersionSlug = createUrlPathFromArray([origin, fullSlug]);

      if (englishVersionSlug) {
        let sanitisedEnglishVersionSlug =
          removeDefaultLanguagePrefix(englishVersionSlug) || "";

        sanitisedEnglishVersionSlug = ensureTrailingSlash(
          sanitisedEnglishVersionSlug
        );

        return sanitisedEnglishVersionSlug;
      }
    }

    const [englishAlternate] = alternates.filter((item) => {
      return item.full_slug.startsWith("en-gb/");
    });

    if (englishAlternate) {
      const { full_slug: englishAlternateSlug } = englishAlternate;

      const englishVersionSlug = createUrlPathFromArray([
        origin,
        englishAlternateSlug,
      ]);

      if (englishVersionSlug) {
        let sanitisedEnglishSlug =
          removeDefaultLanguagePrefix(englishVersionSlug) || "";
        sanitisedEnglishSlug = ensureTrailingSlash(sanitisedEnglishSlug);

        return sanitisedEnglishSlug;
      }
    }

    return "";
  }

  return "";
};
